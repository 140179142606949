<template>
  <div class="home">
    <h1 class="font-semibold text-xl mb-5 flex justify-between items-center">
      Товары на складе ({{ filters.total }})

      <el-button
        @click="download"
        :disabled="loading"
        :icon="loading ? 'el-icon-loading' : ''"
        size="small"
        plain
        type="success"
        >Скачать</el-button
      >
    </h1>

    <el-row class="mb-4" :gutter="20">
      <el-col :xs="24" :sm="12" :md="4">
        <div class="w-full">
          <el-select
            v-model="filters.warehouse_id"
            @change="handleFiltersChange"
            size="small"
            clearable
            filterable
            placeholder="Выберите склад"
            class="w-full"
          >
            <el-option
              v-for="x in warehouses"
              :key="x.id"
              :label="x.name"
              :value="x.id"
            ></el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="4">
        <div class="w-full">
          <el-select
            v-model="filters.category_id"
            @change="handleFiltersChange"
            size="small"
            clearable
            filterable
            placeholder="Выберите категорию"
            class="w-full"
          >
            <el-option
              v-for="x in categories"
              :key="x.id"
              :label="x.name"
              :value="x.id"
            ></el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>

    <ul class="flex justify-between border border-r-0 my-4">
      <li
        v-for="(value, key) in summary"
        :key="key"
        class="p-3 w-full border-r"
      >
        <div class="text-gray-500">{{ value.title }}</div>
        <div>Кол-во товаров: {{ value.count }}</div>
        <div class="font-semibold text-lg">
          {{ numberFormat(value.total) }} тг
        </div>
      </li>
    </ul>

    <el-table
      border
      size="small"
      v-loading="loading"
      :data="tableData"
      stripe
      style="width: 100%"
    >
      <el-table-column
        align="center"
        type="index"
        width="50"
        label="N"
        :index="indexMethod"
      >
      </el-table-column>
      <el-table-column
        width="100"
        prop="warehouse_name"
        label="Склад"
      ></el-table-column>

      <el-table-column>
        <template slot="header" slot-scope="scope">
          <el-input
            v-model="search"
            clearable
            size="medium"
            placeholder="Поиск товара"
          />
          {{ scope.row }}
        </template>
        <template slot-scope="scope">
          [<router-link
            target="_blank"
            class="text-blue-500"
            :to="{ name: 'Product', params: { id: scope.row.product_id } }"
            >{{ scope.row.product_id }}</router-link
          >] - {{ scope.row.product_name }}
        </template>
      </el-table-column>

      <el-table-column
        prop="product_category_name"
        label="Категория"
      ></el-table-column>

      <el-table-column
        sortable
        width="150"
        align="right"
        prop="booked"
        label="Кол-во брони"
      ></el-table-column>

      <el-table-column sortable width="150" align="right" label="Остаток">
        <template slot-scope="scope">
          {{ scope.row.total - scope.row.booked - scope.row.pre_order }}
        </template>
      </el-table-column>

      <el-table-column
        sortable
        width="150"
        align="right"
        prop="pre_order"
        label="Предзаказ"
      ></el-table-column>

      <el-table-column
        sortable
        width="150"
        align="right"
        prop="avg_price"
        label="Сред цена"
      ></el-table-column>
    </el-table>
    <el-pagination
      class="text-center my-5"
      background
      :disabled="loading"
      layout="prev, pager, next"
      :total="filters.total"
      :page-size="filters.limit"
      :current-page.sync="filters.page"
      @current-change="() => handleFiltersChange('page')"
    >
    </el-pagination>
  </div>
</template>
<script>
import { getStorageProducts } from "@/api/storage";
import { dateTimeFormat, numberFormat } from "@/helpers/formatter";
import { getCategories } from "@/api/category";
import { getWarehouses } from "@/api/accounting";

export default {
  name: "Home",
  data: () => ({
    search: "",

    data: [],
    categories: [],
    warehouses: [],
    loading: false,
    filters: {
      category_id: null,
      warehouse_id: null,
      total: 0,
      pages_count: 0,
      page: 1,
      limit: 5000,
    },

    summary: {},
  }),
  computed: {
    tableData() {
      return this.data.filter(
        (d) =>
          !this.search ||
          d.product_name
            .toLowerCase()
            .includes(this.search.trim().toLowerCase())
      );
    },
  },
  async created() {
    this.categories = await getCategories();
    this.warehouses = await getWarehouses();
    this.parseUrlQuery();
    await this.getAccountings();
  },
  methods: {
    async getAccountings() {
      this.loading = true;
      const result = await getStorageProducts(this.filters);
      const { storage, pages_count, total } = result;
      this.data = storage;
      this.filters.pages_count = pages_count;
      this.filters.total = total;
      this.loading = false;
      const summary = {
        all: { title: "Все", count: 0, total: 0 },
        remainder: { title: "Остаток", count: 0, total: 0 },
        booked: { title: "Бронь", count: 0, total: 0 },
        preOrder: { title: "Предзаказ", count: 0, total: 0 },
      };
      for (const s of storage) {
        summary.all.count += s.total;
        summary.all.total += s.total * s.price;

        summary.remainder.count += s.total - s.booked - s.pre_order;
        summary.remainder.total += (s.total - s.booked - s.pre_order) * s.price;

        summary.booked.count += s.booked;
        summary.booked.total += s.booked * s.price;

        summary.preOrder.count += s.pre_order;
        summary.preOrder.total += s.pre_order * s.price;
      }

      this.summary = summary;
      console.log("summary", summary);
    },
    numberFormat(number) {
      return numberFormat(number);
    },
    async download() {
      this.loading = true;
      await getStorageProducts({
        ...this.filters,
        action: "download",
      });
      this.$message.success("Успешно");
      setTimeout(() => {
        window.open("https://api.alasal.kz/export-warehouse.xlsx", "_blank");
      }, 500);
      this.loading = false;
    },
    parseUrlQuery() {
      const { page, category_id, warehouse_id } = this.$route.query;
      this.filters.page = page ? parseInt(page) : 1;
      this.filters.category_id = category_id ? parseInt(category_id) : null;
      this.filters.warehouse_id = warehouse_id ? parseInt(warehouse_id) : null;
    },
    changeUrlSearchParams() {
      const { page, category_id, warehouse_id } = this.filters;
      this.$router.push({
        query: {
          page,
          category_id,
          warehouse_id,
        },
      });
    },
    async handleFiltersChange(param) {
      if (param !== "page") {
        this.filters.page = 1;
      }
      this.changeUrlSearchParams();
      await this.getAccountings();
    },
    indexMethod(index) {
      return index + 1 + (this.filters.page - 1) * this.filters.limit;
    },
    dateTimeFormat(timestamp) {
      return dateTimeFormat(timestamp * 1000);
    },
  },
};
</script>
